import * as React from "react";
import { navigate } from 'gatsby';

import { Button, Container, Form, FormLabel } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import Layout from "../components/Layout";
export default function ForgotPasswordConfirmationPage() {
  const [password, setPassword] = React.useState("");
  const [passwordRepeat, setPasswordRepeat] = React.useState("");

  const handleSubmit = (e) => {
    const emailvalue =
      typeof window !== "undefined"
        ? new URLSearchParams(window.location.search).get("email")
        : "";
    const code =
      typeof window !== "undefined"
        ? new URLSearchParams(window.location.search).get("code")
        : "";

    const passwordvalue = document.getElementById("newpassword")?.value;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "Email": emailvalue,
      "Code": code,
      "Password": passwordvalue
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://localhost:44319/api/Account/ResetPasswordCode", requestOptions)
      .then(response => {
        if (response.status === 200) {
          navigate("/user-login");
          return response?.text() ?? "";
        }
      })
      .then(result => console.log(result))
      .catch(error => console.log('error', error)); e.preventDefault();

  }
  return (
    <Layout>
      <main>
        <Container>
          <section>
            <div className="col-xl-5 col-lg-5 col-md-12 pt-3 mx-auto text-center">
              <h1 className="fs-48 fx-blod mb-5">Forgot Password</h1>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 pb-5 mx-auto text-center">
              <form className="text-start my-5" onSubmit={handleSubmit}>
                <Form.Group className="mb-3" >
                  <FormLabel className="fs-13 bogo-par">New password</FormLabel>
                  <Form.Control type="password" placeholder="New password" id="newpassword" onChange={(e) => setPassword(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <FormLabel className="fs-13 bogo-par">
                    Confirm password
                  </FormLabel>
                  <Form.Control type="password" placeholder="Confirm password" id="confirmpassword"
                    onBlur={(e) => {
                      var thisValue = e.target.value;
                      var FirstValue = document.getElementById('newpassword').value;

                      if (thisValue === FirstValue) {

                        setPasswordRepeat(e.target.value)
                      }
                      else {
                        document.querySelector(".changepassword").style.display = "block";
                      }

                    }} />
                </Form.Group>
                <p className="changepassword" style={{ display: "none", fontSize: "11px", color: "red" }}>the password doesnt match</p>

                <Button
                  variant="primary"
                  className="w-100 fs-14 h-52"
                  type="submit"
                >
                  Change Password
                </Button>
              </form>
            </div>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
